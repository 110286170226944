import React, { useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Head from 'next/head';
import ImageComponent from '@fuww/library/src/Image';

const imagePropertyTypes = {
  alt: PropTypes.string.isRequired,
  cacheKey: PropTypes.string,
  className: PropTypes.string,
  finalFetchpriority: PropTypes.string,
  height: PropTypes.string,
  imageClassName: PropTypes.string,
  loadStrategy: PropTypes.string,
  maxHeight: PropTypes.string,
  objectFit: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  placeholderFetchpriority: PropTypes.string,
  placeholderLoadStrategy: PropTypes.string,
  preload: PropTypes.bool,
  preloadMediaQuery: PropTypes.string,
  showOnlyOn: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  width: PropTypes.string,
  padding: PropTypes.string,
  disableRoundedCorners: PropTypes.bool,
};

type ImagePropertyTypes = InferProps<typeof imagePropertyTypes>;

const Image = ({
  alt,
  cacheKey,
  className,
  finalFetchpriority = 'low',
  height = 'auto',
  imageClassName,
  loadStrategy = 'on-visible',
  maxHeight = 'none',
  objectFit = 'contain',
  placeholder,
  placeholderFetchpriority = 'low',
  placeholderLoadStrategy = loadStrategy,
  preload = false,
  preloadMediaQuery,
  showOnlyOn,
  sizes,
  src,
  srcSet,
  width = 'auto',
  padding,
  disableRoundedCorners,
  ...properties
}: ImagePropertyTypes) => {
  const reference: React.RefObject<HTMLImageElement> = useRef(null);

  const image = (
    <progressive-img
      alt={alt}
      class={className ?? undefined}
      final-fetchpriority={finalFetchpriority}
      placeholder-fetchpriority={placeholderFetchpriority}
      placeholder={placeholder}
      ref={reference}
      sizes={sizes}
      src={src}
      srcset={srcSet}
      title={alt}
      {...loadStrategy === 'on-visible' ? {} : {
        'load-strategy': loadStrategy,
      }}
      {...placeholderLoadStrategy === 'on-visible' ? {} : {
        'placeholder-load-strategy': placeholderLoadStrategy,
      }}
      {...properties}
    />
  );

  return (
    <>
      {preload && (
        <Head>
          <link
            as="image"
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={placeholderFetchpriority ?? 'low'}
            href={placeholder}
            media={preloadMediaQuery ?? undefined}
            rel="preload"
          />
          <link
            as="image"
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={finalFetchpriority ?? 'low'}
            href={src}
            imageSizes={sizes}
            imageSrcSet={srcSet}
            media={preloadMediaQuery ?? undefined}
            rel="preload"
          />
        </Head>
      )}
      <ImageComponent
        alt={alt}
        className={imageClassName}
        height={height}
        image={image}
        maxHeight={maxHeight}
        objectFit={objectFit}
        showOnlyOn={showOnlyOn}
        sizes={sizes}
        src={src}
        srcSet={srcSet}
        width={width}
        padding={padding}
        disableRoundedCorners={disableRoundedCorners}
      />
    </>
  );
};

Image.propTypes = imagePropertyTypes;

export default Image;
