import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import breakpoints from './Theme/breakpoints';
import { mediumRoundedCorners } from './RoundedCorners';

const imageContainerPropertyTypes = {
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  objectFit: PropTypes.string,
  showOnlyOn: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
  disableRoundedCorners: PropTypes.bool,
};

type ImageContainerProperties = InferProps<typeof imageContainerPropertyTypes>;

const ImageContainer = styled.div<ImageContainerProperties>`
  ${({ width }) => (width && `
    width: ${width};
  `)};
  ${({ height }) => (height && `
    height: ${height};
  `)};
  ${({ padding }) => (padding && `
    padding: ${padding};
  `)};

  /* stylelint-disable-next-line selector-type-no-unknown */
  progressive-img, img {
    ${({ width }) => (width && `
      width: ${width};
      --img-width: ${width};
    `)}
    ${({ objectFit }) => (objectFit && `
      object-fit: ${objectFit};
      --img-object-fit: ${objectFit};
    `)}
    ${({ height }) => (height && `
      height: ${height};
      --img-height: ${height};
    `)}
    ${({ maxHeight }) => (maxHeight && `
      max-height: ${maxHeight};
      --img-max-height: ${maxHeight};
    `)}

    @media (min-width: ${breakpoints.sm}px) {
      ${({ disableRoundedCorners }) => (disableRoundedCorners
    ? 'border-radius: 0' : mediumRoundedCorners)};
      overflow: hidden;
    }
  }

  display: ${({ showOnlyOn }) => (showOnlyOn === 'desktop' ? 'none' : 'block')};

  @media (min-width: ${breakpoints.sm}px) {
    ${({ disableRoundedCorners }) => (disableRoundedCorners
    ? 'border-radius: 0' : mediumRoundedCorners)};
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.lg}px) {
    display: ${({ showOnlyOn }) => (showOnlyOn === 'mobile'
    ? 'none' : 'block')};
  }
`;

ImageContainer.propTypes = imageContainerPropertyTypes;

const NoScript = styled.noscript`
  min-width: 100%;
`;

const imagePropertyTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  image: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  objectFit: PropTypes.string,
  showOnlyOn: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  width: PropTypes.string,
  padding: PropTypes.string,
  disableRoundedCorners: PropTypes.bool,
};

type ImageProperties = InferProps<typeof imagePropertyTypes>;

const Image = ({
  alt,
  className,
  height = 'auto',
  image,
  maxHeight = 'none',
  objectFit = 'contain',
  showOnlyOn,
  sizes,
  src,
  srcSet,
  width = 'auto',
  padding,
  disableRoundedCorners,
}: ImageProperties) => (
  <ImageContainer
    className={className ?? undefined}
    height={height}
    maxHeight={maxHeight}
    objectFit={objectFit}
    showOnlyOn={showOnlyOn}
    width={width}
    padding={padding}
    disableRoundedCorners={disableRoundedCorners}
  >
    {image}
    <NoScript>
      <img
        alt={alt}
        src={src}
        sizes={sizes}
        srcSet={srcSet}
      />
    </NoScript>
  </ImageContainer>
);

Image.propTypes = imagePropertyTypes;

export default Image;
