import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Image from './Image';

const imageWithUrlsPropertyTypes = {
  alt: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  objectFit: PropTypes.string,
  srcSet: PropTypes.string.isRequired,
  preload: PropTypes.bool,
  finalFetchpriority: PropTypes.string,
  placeholderFetchpriority: PropTypes.string,
  placeholderLoadStrategy: PropTypes.string,
  padding: PropTypes.string,
  disableRoundedCorners: PropTypes.bool,
};

type ImageWithUrlsProperties = InferProps<typeof imageWithUrlsPropertyTypes>;

const ImageWithUrls = ({
  imageUrls,
  alt,
  width = '100%',
  height = 'auto',
  sizes,
  objectFit = 'cover',
  padding,
  disableRoundedCorners,
  ...rest
} : ImageWithUrlsProperties) => (
  <Image
    placeholder={imageUrls[0]}
    src={imageUrls[1]}
    alt={alt}
    width={width}
    height={height}
    objectFit={objectFit}
    sizes={sizes}
    padding={padding}
    disableRoundedCorners={disableRoundedCorners}
    {...rest}
  />
);

ImageWithUrls.propTypes = imageWithUrlsPropertyTypes;

export default ImageWithUrls;
